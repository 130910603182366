import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { DirectusService } from './directus.service';
import { SchoolClass } from '../types/schoolClass';

@Injectable({
  providedIn: 'root',
})
export class SchoolClassService {
  constructor(private directusService: DirectusService) {}

  getSchoolClass(level: string): Observable<SchoolClass> {
    return this.directusService
      .readMany('schoolclass', {
        filter: {
          level: { _eq: level },
        },
      })
      .pipe(
        map((items: any) => {
          return items.data;
        }),
        take(1)
      );
  }

  getSchoolClasses(schoolYear: string): Observable<SchoolClass[]> {
    return this.directusService
      .readMany('schoolclass', {
        filter: {
          schoolyear: {
            _eq: schoolYear,
          },
        },
        sort: 'level',
      })
      .pipe(
        map((items: any) => {
          return items.data;
        })
      );
  }
}
